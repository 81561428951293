<template>
  <div>
    <section class="main-content">
      <div class="grid-x grid-margin-x">
        <div class="cell small-12 medium-9 large-3">
          <ul class="vertical tabs">
            <li
              v-for="tab in tabs"
              v-bind:key="tab.component"
              v-bind:class="[
                'tabs-title',
                { 'is-active': currentTab === tab.id },
              ]"
            >
              <span
                v-bind:aria-selected="currentTab === tab.id"
                v-on:click="setCurrentTab(tab.id)"
              >
                <component v-bind:is="tab.icon" />
                {{ $t(tab.title) }}
              </span>
            </li>
          </ul>
        </div>
        <div class="cell small-12 large-9">
          <div class="tabs-content">
            <div class="grid-x align-justify">
              <div class="cell small-12 medium-shrink">
                <h1 class="heading heading-2">{{ $t(currentTabTitle) }}</h1>
              </div>
              <div class="cell small-12 medium-shrink">
                <button-filter
                  :initialStartDate="$moment().format()"
                  :initialEndDate="$moment().format()"
                  v-on:change="onFiltersChanged"
                />
              </div>
            </div>
            <filters
              :channel="selectedChannel"
              :agents="selectedAgents"
              :date="date"
            />
            <div class="tabs-panel is-active">
              <keep-alive>
                <component
                  v-bind:is="currentTabComponent"
                  v-bind="globalFilters"
                />
              </keep-alive>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import ChartLoader from '@/components/loaders/Chart.vue';
import PieChartLoader from '@/components/loaders/PieChart.vue';
import Filters from '@/components/Filters.vue';

export default {
  components: {
    ChartLoader,
    PieChartLoader,
    Filters,
    ButtonFilter: () => import('@/components/ButtonFilter.vue'),
    DashboardStore: () => import ('@/components/DashboardStore.vue'),
    DashboardAgents: () => import('@/components/DashboardAgents.vue'),
    DashboardConversations: () => import('@/components/DashboardConversations.vue'),
    DashboardMessages: () => import('@/components/DashboardMessages.vue'),
    ConversationsIvr: () => import ('@/components/ConversationsIvr.vue'),
  },
  data() {
    return {
      filters: null,
      currentTab: 1,
      selectedChannel: null,
      selectedAgents: [],
      date: {},
      defaultStartDate: this.$moment().startOf('day').toDate(),
      defaultEndDate: this.$moment().endOf('day').toDate(),
    };
  },
  computed: {
    ...mapGetters({
      userInfo: 'shared/userInfo',
    }),
    globalFilters() {
      return {
        filters: this.filters,
      };
    },
    currentTabObject() {
      return this.tabs.find(tab => tab.id === this.currentTab);
    },
    currentTabComponent() {
      return this.currentTabObject.component;
    },
    currentTabTitle() {
      return this.currentTabObject.title;
    },
    isOwner() {
      return this.userInfo.role === 'owner';
    },
    tabs() {
      return [
        {
          id: 1,
          icon: 'message-text-icon',
          title: 'dashboard.conversations',
          component: 'dashboard-conversations',
        },
        {
          id: 2,
          icon: 'text-subject-icon',
          title: 'app.messages',
          component: 'dashboard-messages',
        },
        {
          id: 3,
          icon: 'account-multiple-icon',
          title: 'dashboard.agents',
          component: 'dashboard-agents',
        },
        {
          id: 4,
          icon: 'account-multiple-icon',
          title: 'app.bot',
          component: 'conversations-ivr',
        },
        {
          id: 5,
          icon: 'chart-box-outline',
          title: 'dashboard.store',
          component: 'dashboard-store',
        },
      ];
    },
  },
  mounted() {
    this.setLang(this.$moment.locale());
  },

  methods: {
    ...mapMutations({
      setLang: 'shared/setLang',
    }),
    setCurrentTab(tabId) {
      this.currentTab = tabId;
    },
    onFiltersChanged(filters) {
      let { startDate, endDate } = filters;
      if (startDate === null) {
        startDate = this.$moment(this.defaultStartDate).format('YYYY-MM-DD');
        endDate = this.$moment(this.defaultEndDate).format('YYYY-MM-DD');
      }

      this.filters = {
        companyId: filters.companyId,
        campaignId: filters.campaignId,
        channelId: filters.channelId,
        agentId: filters.agentId,
        startDate,
        endDate,
      };

      this.date = { start: startDate, end: endDate };

      if (filters.channelId) {
        this.selectedChannel = filters.channelId;
      } else {
        this.selectedChannel = this.$t('filter.all');
      }

      if (filters.agentId) {
        this.selectedAgents = filters.agentId;
      } else {
        this.selectedAgents = [];
      }
    },

  },
};
</script>

<style scoped lang="scss">
@import "~styles/views/_dashboard.scss";
</style>
