<template>
  <div class="channels__enable-settings">
    <div>
      <div class="channels__enable-settings-title">
        {{ title }}
      </div>
      <div class="channels__enable-settings-description">
        {{ enableSettings[titleKey] }}
      </div>
      <div
        class="channels__warning"
        :class="toggleValue && 'channels__warning-gray'"
      >
        <span>{{ enableSettings["override-settings"] }}</span>
        <span
          class="channels__warning-bold"
          :class="toggleValue && 'channels__warning-gray'"
        >
          {{ enableSettings["only-channel"] }}
        </span>
        <span>{{ enableSettings["this-channel"] }}</span>
      </div>
      <transition name="fade" v-if="toggleValue">
        <div class="channels__warning-red">
          <span>{{ enableSettings["apply-changes"] }}</span>
          <span class="channels__warning-bold">
            {{ enableSettings["only-channel"] }}
          </span>
          <span>{{ enableSettings["press-save"] }}</span>
        </div>
      </transition>
    </div>
    <hcc-toggle-switch
      :name="name"
      :value="toggleValue"
      @change="toggleEnable"
    />
  </div>
</template>

<script>
import HccToggleSwitch from '@/components/shared/HccToggleSwitch/index.vue';

export default {
  components: {
    HccToggleSwitch,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    titleKey: {
      type: String,
      required: true,
    },
    toggleValue: {
      type: Boolean,
    },
  },
  computed: {
    enableSettings() {
      return this.$t('channels-settings.enable-settings');
    },
    name() {
      return `${this.titleKey}-toggle`;
    },
  },
  methods: {
    toggleEnable() {
      this.$emit('toggle-enable');
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/views/channelSettings/_campaign-channels-settings.scss";
</style>
