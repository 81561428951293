<template>
  <div :key="id">{{ $t("login.title") }}</div>
</template>

<script>
import { mapMutations } from 'vuex';
import jwt from 'jsonwebtoken';
import { onLogin, getToken } from '@/vue-apollo';

// TODO: change url to new loging
const redirectUrl = process.env.VUE_APP_REDIRECT_URL ?? 'https://app.heycenter.com';

export default {
  data() {
    return {
      token: getToken(),
      today: this.$moment(new Date())
        .format(),
    };
  },
  mounted() {
    this.getInit();
  },
  computed: {
    id() {
      return this.randomID();
    },
  },
  methods: {
    ...mapMutations({
      updateAccessToken: 'dashboard/updateAccessToken',
      setUser: 'shared/setUser',
    }),
    getInit() {
      const { data } = this.$route.query;
      if (data) {
        this.setToken(data);
      } else if (!this.token) {
        window.location.href = redirectUrl;
      } else if (this.validToken(this.token)) {
        this.$router.push('/dashboard');
      } else {
        window.location.href = redirectUrl;
      }
    },
    setToken(payload) {
      if (this.validToken(payload)) {
        this.updateAccessToken(payload);
        onLogin(this.$apollo.provider.defaultClient, payload)
          .then(() => this.$router.push('/dashboard'))
          .catch(() => {
            window.location.href = redirectUrl;
          });
      } else {
        window.location.href = redirectUrl;
      }
    },
    validToken(payload) {
      const user = jwt.decode(payload);
      if (user && user.id && user.exp && user.role) {
        const expires = this.$moment.unix(user.exp);
        if (user.role === 'agent' || !expires.isValid() || expires.format() <= this.today) {
          return false;
        }
        this.setUser(user);
        return true;
      }
      return false;
    },
  },
};
</script>
