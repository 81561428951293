<template>
  <div class="messages-container">
    <enable-settings
      :title="enableSettingsTitle"
      titleKey="messages"
      :toggleValue="enable"
      @toggle-enable="toggleEnable"
    />
    <div
      class="grid-x"
      :class="[
        !enable && 'messages-container-disabled',
        'messages-container-margin',
      ]"
    >
      <hcc-card-header :title="messagesSettings.title" />
      <hcc-card class="cell large-6 custom-cell" color="gray">
        <div class="messages">
          <div class="messages__item">
            <hcc-textarea
              name="welcome"
              :label="messagesSettings.welcome"
              :rows="5"
              :disabled="!enable"
              class="messages__textarea"
              v-model="welcomeMessage"
              @input="changeData($event, 'welcomeMessage')"
            />
            <div class="messages__optional-message">
              {{ messagesSettings["welcome-message"] }}
            </div>
          </div>
          <div class="messages__item">
            <hcc-textarea
              name="outOfService"
              :label="messagesSettings['out-of-service']"
              :rows="5"
              :disabled="!enable"
              class="messages__textarea"
              v-model="messageOutOfService"
              @input="changeData($event, 'messageOutOfService')"
            />
            <div class="messages__optional-message">
              {{ messagesSettings["oos-message"] }}
            </div>
          </div>
          <div class="messages__item">
            <hcc-textarea
              name="noAgents"
              :label="messagesSettings['no-agents']"
              :rows="5"
              :disabled="!enable"
              class="messages__textarea"
              v-model="agentsOfflineMessage"
              @input="changeData($event, 'agentsOfflineMessage')"
            />
            <div class="messages__optional-message">
              {{ messagesSettings["no-agents-message"] }}
            </div>
          </div>
          <div class="messages__item">
            <hcc-textarea
              name="automaticLogout"
              :label="messagesSettings['automatic-logout']"
              :rows="5"
              :disabled="!enable"
              class="messages__textarea"
              v-model="automaticLogoutMessage"
              @input="changeData($event, 'automaticLogoutMessage')"
            />
            <div class="messages__optional-message">
              {{ messagesSettings["automatic-logout-message"] }}
            </div>
          </div>
        </div>
      </hcc-card>
      <hcc-card
        class="cell large-6 custom-cell"
        color="gray"
        bodyClass="messages__preview"
      >
        <div v-if="hasPreview" class="messages__preview-title">
          {{ previewTitle }}
        </div>
        <chat-preview
          :messages="getPreviewMessages()"
          :iterate="false"
          :channelsTab="true"
          @animationCompleted="animationCompleted"
        />
      </hcc-card>
    </div>
    <div class="channels__edit">
      <hcc-button :disabled="!enable" @click="handleConfirm">{{
        save
      }}</hcc-button>
    </div>
    <transition name="fade">
      <hcc-confirmation />
    </transition>
  </div>
</template>

<script>
import HccCard from '@/components/shared/HccCard/index.vue';
import HccCardHeader from '@/components/shared/HccCard/HccCardHeader.vue';
import HccConfirmation from '@/components/shared/HccConfirmation/index.vue';
import HccTextarea from '@/components/shared/HccTextarea/index.vue';
import ChatPreview from '@/components/ChatPreview.vue';
import HccButton from '@/components/shared/HccButton/index.vue';
import EnableSettings from './EnableSettings.vue';

export default {
  components: {
    HccCard,
    HccCardHeader,
    HccConfirmation,
    HccTextarea,
    HccButton,
    ChatPreview,
    EnableSettings,
  },
  props: {
    channel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      enable: false,
      welcomeMessage: '',
      messageOutOfService: '',
      agentsOfflineMessage: '',
      automaticLogoutMessage: '',
      unsavedChanges: false,
      messages: [],
      previewList: [],
      indexPreview: 0,
    };
  },
  computed: {
    enableSettingsTitle() {
      return this.$t('channels-settings.enable-settings.title', { type: this.messagesSettings.title });
    },
    messagesSettings() {
      return this.$t('channels-settings.messages');
    },
    save() {
      return this.$t('channels-settings.save');
    },
    hasPreview() {
      return this.previewList.length > 0;
    },
    previewTitle() {
      return this.previewList[this.indexPreview] ? this.previewList[this.indexPreview].title : '';
    },
  },
  inject: ['updateChannel'],
  methods: {
    toggleEnable() {
      this.enable = !this.enable;

      const channel = {
        id: this.channel.id,
        overrideMessages: this.enable,
      };

      this.updateChannel(channel, this.channel, this.messagesSettings.title)
        .then((result) => {
          if (result.data.saveChannel.overrideMessages) {
            this.updatePreviewMessages();
          } else {
            this.clearData();
          }
        });
    },
    setData() {
      if (this.channel) {
        this.enable = this.channel.overrideMessages || false;

        if (this.channel.messages && this.channel.messages.length > 0) {
          this.welcomeMessage = this.findMessage('welcomeMessage') ? this.findMessage('welcomeMessage').value : '';
          this.messageOutOfService = this.findMessage('messageOutOfService') ? this.findMessage('messageOutOfService').value : '';
          this.agentsOfflineMessage = this.findMessage('agentsOfflineMessage') ? this.findMessage('agentsOfflineMessage').value : '';
          this.automaticLogoutMessage = this.findMessage('automaticLogoutMessage') ? this.findMessage('automaticLogoutMessage').value : '';

          if (this.enable) {
            this.updatePreviewMessages();
          }
        }
      }
    },
    clearData() {
      this.previewList = [];
      this.indexPreview = 0;
    },
    findMessage(key) {
      return this.channel.messages.find(msg => msg.name === key);
    },
    changeData(value, key) {
      if (!this.unsavedChanges) {
        this.unsavedChanges = true;
        this.$emit('toggle-change', this.unsavedChanges);
      }

      const i = this.messages.findIndex(message => message.name === key);

      if (i === -1) {
        this.messages.push({
          name: key,
          value,
        });
      } else {
        this.messages[i].value = value;
      }
    },
    handleConfirm() {
      this.unsavedChanges = false;
      this.$emit('toggle-change', this.unsavedChanges);

      const channel = {
        id: this.channel.id,
        messages: this.messages,
      };

      this.updateChannel(channel, this.channel, this.messagesSettings.title);
    },
    getPreviewMessages() {
      return this.previewList.length > 0 && this.previewList[this.indexPreview] && this.enable
        ? this.previewList[this.indexPreview].messages : this.previewList;
    },
    updatePreviewMessages() {
      const channelMessages = this.channel.messages.map(message => ({
        text: message.value,
        name: message.name,
      }));

      channelMessages.forEach((message) => {
        this.createMessage(message);
      });
    },
    createMessage(message) {
      const previewMessages = [];

      if (message.name === 'welcomeMessage') {
        previewMessages.push({
          text: this.$t('configuration.messages.welcome-text'),
          time: '15:00',
          type: 'sent',
        });

        previewMessages.push({
          text: message.text,
          time: '15:00',
          type: 'received',
        });

        this.previewList.push({
          messages: previewMessages,
          title: this.messagesSettings.welcome,
        });
      } else if (message.name === 'messageOutOfService') {
        previewMessages.push({
          text: this.$t('configuration.messages.out-of-service.text'),
          time: '22:30',
          type: 'sent',
        });

        previewMessages.push({
          text: message.text,
          time: '22:30',
          type: 'received',
        });

        previewMessages.push({
          text: this.$t('configuration.messages.out-of-service.confirm'),
          time: '22:30',
          type: 'sent',
        });

        this.previewList.push({
          messages: previewMessages,
          title: this.messagesSettings['out-of-service'],
        });
      } else if (message.name === 'agentsOfflineMessage') {
        previewMessages.push({
          text: this.$t('configuration.messages.agents-offline'),
          time: '16:00',
          type: 'sent',
        });

        previewMessages.push({
          text: message.text,
          time: '16:00',
          type: 'received',
        });

        previewMessages.push({
          text: this.$t('configuration.messages.out-of-service.confirm'),
          time: '16:00',
          type: 'sent',
        });

        this.previewList.push({
          messages: previewMessages,
          title: this.messagesSettings['no-agents'],
        });
      } else {
        previewMessages.push({
          text: this.$t('configuration.messages.unattended.received'),
          time: `${this.messagesSettings.yesterday} 10:00'`,
          type: 'sent',
        });

        previewMessages.push({
          text: this.$t('configuration.messages.unattended.send'),
          time: `${this.messagesSettings.yesterday} 10:00'`,
          type: 'received',
        });

        previewMessages.push({
          text: message.text,
          time: `${this.messagesSettings.today}} 10:00'`,
          type: 'received',
        });

        this.previewList.push({
          messages: previewMessages,
          title: this.messagesSettings['automatic-logout'],
        });
      }
    },
    animationCompleted() {
      this.indexPreview = this.previewList.length > 0
        ? (this.indexPreview + 1) % this.previewList.length : 0;
    },

  },
  created() {
    this.setData();
  },
};


</script>

<style scoped lang="scss">
@import "~styles/views/channelSettings/_campaign-channels-settings.scss";
</style>
