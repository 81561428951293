<template>
  <div class="options">
    <div class="option-card">
      <div class="option-card-input">
        <div class="option-card__title">{{ title }}</div>
        <hcc-input
          v-if="hasInput"
          class="option-card__input"
          type="number"
          :disabled="disabled"
          :value="value"
          @input="changeInput"
        />
        <div
          class="option-card__description"
          :class="message && 'option-card__description-small-margin'"
        >
          {{ description }}
        </div>
        <div class="option-card__description-message" v-if="message">
          {{ message }}
        </div>
      </div>
      <hcc-toggle-switch
        v-if="!hasInput"
        :name="toggleName"
        :value="value"
        :disabled="disabled"
        @change="toggleValue"
      />
    </div>
  </div>
</template>

<script>
import HccInput from '@/components/shared/HccInput/index.vue';
import HccToggleSwitch from '@/components/shared/HccToggleSwitch/index.vue';

export default {
  components: {
    HccInput,
    HccToggleSwitch,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    message: {
      type: String,
    },
    hasInput: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    channel: {
      type: Object,
      required: true,
    },
    optionKey: {
      type: String,
      required: true,
    },
    optionValue: {
      type: [Number, Boolean],
      required: true,
    },
  },
  computed: {
    toggleName() {
      return `toggle-${this.optionKey}`;
    },

    value() {
      return this.channel.config ? this.channel.config[this.optionKey] : this.optionValue;
    },
  },
  methods: {
    changeInput(value) {
      this.$emit('change-input', value);
    },
    toggleValue(value) {
      this.$emit('toggle', value);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/views/channelSettings/_campaign-channels-settings.scss";
</style>
