<template>
  <div class="empty-channels">
    <div class="empty-channels__container">
      <component
        :is="tab.key"
        class="empty-channels__icon"
        :class="tab.key === 'telegram' && 'empty-channels__icon-telegram'"
      />
    </div>
    <span>{{ noChannels }} </span>
    <hcc-button
      v-if="canCreate"
      class="empty-channels__button"
      @click="createChannel"
      >{{ createChannelLabel }}</hcc-button
    >
  </div>
</template>

<script>
import HccButton from '@/components/shared/HccButton/index.vue';

export default {
  components: {
    HccButton,
  },
  props: {
    tab: {
      type: Object,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
  },
  computed: {
    canCreate() {
      return this.role === 'owner' || this.role === 'super';
    },
    noChannels() {
      return this.$t('channels-settings.no-channels', { type: this.tab.name });
    },
    createChannelLabel() {
      return this.$t('channels-settings.create-channel');
    },
  },
  methods: {
    createChannel() {
      this.$emit('create-channel', this.tab);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/views/channelSettings/_campaign-channels-settings.scss";
</style>
