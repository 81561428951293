<template>
  <div class="channels__info">
    <div class="channels__group">
      <div>
        <hcc-input
          :label="botUsername"
          v-model="bot"
          customClass="channels__input"
          :disabled="pending"
          @input="changeData($event, 'name')"
        />
        <div class="channels__text">
          <div>{{ "myBot, My_bot..." }}</div>
          <div>
            {{ createYourBot }}
            <a
              href="https://telegram.me/botfather"
              target="_blank"
              class="channels__text-link"
              >BotFather</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="channels__group">
      <hcc-input
        :label="tokenLabel"
        v-model="token"
        customClass="channels__input"
        :disabled="pending"
        @input="changeData($event, 'token')"
      />
    </div>
  </div>
</template>

<script>
import HccInput from '@/components/shared/HccInput/index.vue';

export default {
  components: {
    HccInput,
  },
  props: {
    channel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      bot: '',
      token: '',
      extras: {},
    };
  },
  inject: ['isPending'],
  computed: {
    botUsername() {
      return this.$t('channels-settings.telegram.bot-username');
    },
    createYourBot() {
      return this.$t('channels-settings.telegram.create-bot');
    },
    tokenLabel() {
      return this.$t('channels-settings.telegram.token');
    },
    pending() {
      return this.isPending();
    },
  },
  methods: {
    changeData(value, key) {
      this.extras[key] = value;
      this.telegramExtras();
    },
    telegramExtras() {
      this.$emit('update-extras', this.extras);
    },
  },
  created() {
    this.bot = this.channel.extras ? this.channel.extras.name : '';
    this.token = this.channel.extras ? this.channel.extras.token : '';
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/views/channelSettings/_campaign-channels-settings.scss";
</style>
