<template>
  <div>
    <enable-settings
      :title="enableSettingsTitle"
      titleKey="options"
      :toggleValue="enable"
      @toggle-enable="toggleEnable"
    />
    <div
      class="options-container"
      :class="!enable && 'options-container-disabled'"
    >
      <hcc-card
        :title="optionsSettings.general"
        color="gray"
        class="card-body border"
      >
        <option-card
          v-if="isWhatsapp"
          :title="optionsSettings['hide-username'].title"
          :description="optionsSettings['hide-username'].description"
          :disabled="!enable"
          :channel="channel"
          optionKey="hideUsername"
          :optionValue="false"
          @toggle="changeData($event, 'hideUsername')"
        />
        <option-card
          :title="optionsSettings['chat-history-days'].title"
          :description="optionsSettings['chat-history-days'].description"
          :disabled="!enable"
          :channel="channel"
          optionKey="chatHistoryDays"
          :optionValue="1"
          hasInput
          @change-input="changeData($event, 'chatHistoryDays')"
        />
        <option-card
          :title="optionsSettings['max-conversations-before-assign'].title"
          :description="
            optionsSettings['max-conversations-before-assign'].description
          "
          :message="optionsSettings['max-conversations-before-assign'].message"
          :disabled="!enable"
          :channel="channel"
          optionKey="maxConversationsBeforeAssign"
          :optionValue="10"
          hasInput
          @change-input="changeData($event, 'maxConversationsBeforeAssign')"
        />
        <option-card
          :title="optionsSettings['close-conversations-when-logging-out'].title"
          :description="
            optionsSettings['close-conversations-when-logging-out'].description
          "
          :disabled="!enable"
          :channel="channel"
          optionKey="closeConversationsWhenLoggingOut"
          :optionValue="false"
          @toggle="changeData($event, 'closeConversationsWhenLoggingOut')"
        />
        <option-card
          :title="
            optionsSettings['reassign-conversations-when-logging-out'].title
          "
          :description="
            optionsSettings['reassign-conversations-when-logging-out']
              .description
          "
          :disabled="!enable"
          :channel="channel"
          optionKey="reassignConversationsWhenLoggingOut"
          :optionValue="false"
          @toggle="changeData($event, 'reassignConversationsWhenLoggingOut')"
        />
      </hcc-card>
    </div>
    <div class="channels__edit">
      <hcc-button :disabled="!enable" @click="handleConfirm">{{
        save
      }}</hcc-button>
    </div>
    <hcc-confirmation />
  </div>
</template>

<script>
import HccCard from '@/components/shared/HccCard/index.vue';
import HccButton from '@/components/shared/HccButton/index.vue';
import HccConfirmation from '@/components/shared/HccConfirmation/index.vue';
import OptionCard from './OptionCard.vue';
import EnableSettings from './EnableSettings.vue';

export default {
  components: {
    HccCard,
    HccButton,
    HccConfirmation,
    OptionCard,
    EnableSettings,
  },
  props: {
    channel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      enable: false,
      unsavedChanges: false,
      config: {},
    };
  },
  computed: {
    isWhatsapp() {
      return this.channel && this.channel.channelType ? this.channel.channelType.key === 'whatsapp' : false;
    },
    optionsSettings() {
      return this.$t('channels-settings.options');
    },
    enableSettingsTitle() {
      return this.$t('channels-settings.enable-settings.title', { type: this.optionsSettings.title });
    },
    save() {
      return this.$t('channels-settings.save');
    },
  },
  inject: ['updateChannel'],
  methods: {
    toggleEnable() {
      this.enable = !this.enable;

      const channel = {
        id: this.channel.id,
        overrideConfig: this.enable,
      };

      this.updateChannel(channel, this.channel, this.optionsSettings.title);
    },
    changeData(value, key) {
      if (!this.unsavedChanges) {
        this.unsavedChanges = true;
        this.$emit('toggle-change', this.unsavedChanges);
      }

      let data = value;

      if (key === 'chatHistoryDays' || key === 'maxConversationsBeforeAssign') {
        data = parseInt(value, 10);
      }

      this.config[key] = data;
    },
    handleConfirm() {
      this.unsavedChanges = false;
      this.$emit('toggle-change', this.unsavedChanges);

      const channel = {
        id: this.channel.id,
        config: this.config,
      };

      this.updateChannel(channel, this.channel, this.optionsSettings.title);
    },
  },
  created() {
    this.enable = this.channel ? this.channel.overrideConfig : false;
  },
};
</script>

<style scoped lang="scss">
@import "~styles/views/channelSettings/_campaign-channels-settings.scss";
</style>
