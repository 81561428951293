<template>
  <channel-settings-provider>
    <channel-settings />
  </channel-settings-provider>
</template>

<script>
import ChannelSettings from './ChannelSettings.vue';
import ChannelSettingsProvider from './ChannelSettingsProvider.vue';

export default {
  components: {
    ChannelSettings,
    ChannelSettingsProvider,
  },
};
</script>
