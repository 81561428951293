<template>
  <div
    :class="[radio ? 'radio-button' : 'checkbox-container']"
    @click.stop="() => {}"
  >
    <input
      type="checkbox"
      :name="name"
      :id="name"
      :checked="value"
      :disabled="disabled"
      @change="onChange($event)"
      :class="[size, { checkbox: !radio, selected: selected && !radio }]"
    />
    <label :for="name">{{ label }}</label>
  </div>
</template>

<script>
export default {
  model: {
    event: 'change',
  },
  props: {
    name: {
      type: [String, Number],
      required: true,
    },
    value: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    radio: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    size: {
      type: String,
      default: 'md',
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onChange(event) {
      this.$emit('change', event.target.checked);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/shared/checkbox.scss";
</style>
