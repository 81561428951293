<template>
  <div class="channels__info">
    <div class="channels__group">
      <hcc-input
        :label="channelId"
        v-model="id"
        customClass="channels__input"
        :disabled="pending"
        @input="changeId"
      />
    </div>
  </div>
</template>

<script>
import HccInput from '@/components/shared/HccInput/index.vue';

export default {
  components: {
    HccInput,
  },
  props: {
    channel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      id: '',
      extras: {},
    };
  },
  inject: ['isPending'],
  computed: {
    channelId() {
      return this.$t('channels-settings.webchat.channel-id');
    },
    pending() {
      return this.isPending();
    },
  },
  methods: {
    changeId(value) {
      this.extras.id = value;
      this.webchatExtras();
    },
    webchatExtras() {
      this.$emit('update-extras', this.extras);
    },
  },
  created() {
    this.id = this.channel.extras ? this.channel.extras.id : '';
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/views/channelSettings/_campaign-channels-settings.scss";
</style>
