<template>
  <section class="main-content loader-container" :class="{ flex: pending }">
    <overlay-loader :loading="pending" />
    <div class="channels-settings" v-if="!pending">
      <channels-tabs
        :channels="channels"
        :editedTab="editedTab"
        :selectedChannel="selectedChannel"
        @change-tab="changeChannelTab"
        @change-channel="changeChannel"
        @create-channel="toggleCreateChannel"
      />
      <empty-channels
        v-if="showEmptyPage"
        :tab="!selectedChannelTab ? whatsapp : selectedChannelTab"
        :role="userRole"
        @create-channel="createNewChannel"
      />
      <tabs-container
        :hasChanges="hasChanges"
        :isNew="isNewChannel"
        @toggle-change="toggleChanges"
        @change-mode="changeMode('detail')"
        @update="refreshChannel"
        class="channels__content"
        v-else
      >
        <tabs
          v-for="(tab, index) in tabs"
          :key="index"
          :icon="tab.icon"
          :name="tab.title"
          :selected="tab.selected"
        >
          <component
            :is="tab.component"
            :key="tab.title + displayChannel.id"
            :channel="displayChannel"
            :role="userRole"
            @update="refreshChannel"
            @toggle-change="toggleChanges"
          />
        </tabs>
      </tabs-container>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TabsContainer from '@/components/TabsContainer.vue';
import Tabs from '@/components/TabsSingle.vue';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
import ChannelDetail from './ChannelDetail/index.vue';
import ChannelMessages from './ChannelMessages.vue';
import ChannelSchedules from './ChannelSchedules.vue';
import ChannelOptions from './ChannelOptions.vue';
import ChannelsTabs from './ChannelsTabs.vue';
import EmptyChannels from './EmptyChannels.vue';

export default {
  components: {
    Tabs,
    TabsContainer,
    OverlayLoader,
    ChannelsTabs,
    ChannelDetail,
    ChannelMessages,
    ChannelSchedules,
    ChannelOptions,
    EmptyChannels,
  },
  watch: {
    currentCampaign(newValue, oldValue) {
      if (newValue.id !== oldValue.id) {
        this.setChannels();

        if (newValue.channels.length === 0) {
          this.changeMode('empty');
        } else {
          this.changeMode('detail');
        }

        this.cleanData();
      }
    },
  },
  data() {
    return {
      tabs: [
        {
          icon: 'account-edit-icon',
          title: this.$t('channels-settings.detail'),
          selected: true,
          component: 'channel-detail',
        },
        {
          icon: 'message-icon',
          title: this.$t('channels-settings.messages.title'),
          selected: false,
          component: 'channel-messages',
        },
        {
          icon: 'clock-outline-icon',
          title: this.$t('channels-settings.schedules.title'),
          selected: false,
          component: 'channel-schedules',
        },
        {
          icon: 'wrench-icon',
          title: this.$t('channels-settings.options.title'),
          selected: false,
          component: 'channel-options',
        },
      ],
      channels: [],
      selectedChannelTab: null,
      selectedChannel: {},
      pending: null,
      editedTab: null,
      unsavedChanges: false,
      isNewChannel: false,
    };
  },
  computed: {
    ...mapGetters({
      currentCampaign: 'setting/getCurrentCampaign',
      userRole: 'shared/getUserRole',
    }),
    displayChannel() {
      return this.selectedChannel || this.filterChannelsByType('whatsapp')[0];
    },
    emptyWhatsapp() {
      return !this.filterChannelsByType('whatsapp')[0];
    },
    whatsapp() {
      const whatsapp = {
        name: 'WhatsApp',
        key: 'whatsapp',
        hover: true,
        selected: true,
        channels: [],
      };

      return whatsapp;
    },
    hasChanges() {
      return (this.unsavedChanges || this.getMode() === 'edit');
    },
    mode() {
      return this.getMode();
    },
    showEmptyPage() {
      return this.mode === 'empty'
          || (this.emptyWhatsapp && !this.mode)
          || (this.mode === 'detail' && !this.selectedChannel);
    },
  },
  inject: ['getMode', 'changeMode', 'getChannels'],
  methods: {
    ...mapActions({
      setStoreChannels: 'shop/updateOrCreateChannels',
    }),
    setChannels() {
      this.pending = true;
      this.getChannels(this.currentCampaign.id)
        .then((result) => {
          this.channels = result;
          this.selectedChannel = this.filterChannelsByType('whatsapp')[0] || null;
          this.pending = false;
        });
    },
    async setUpdatedChannels(updatedChannel, channel, tabName) {
      this.pending = true;
      const result = await this.getChannels(this.currentCampaign.id);
      this.channels = result;
      await this.setStoreChannels({
        id: this.currentCampaign.company,
        channels: result,
      });

      let mode = 'detail';

      if (updatedChannel) {
        this.selectedChannel = this.channels.find(chn => chn.id === updatedChannel.id)
      || updatedChannel;
      } else {
        this.selectedChannel = !this.unsavedChanges ? this.selectedChannel : null;

        if (!this.channels.some(chn => chn.id === channel.id)) {
          mode = 'empty';
        }
      }

      this.editedTab = channel;

      this.changeMode(mode);

      const selectedTab = this.tabs.findIndex(tab => tab.selected);
      const newSelectedTab = this.tabs.findIndex(tab => tab.title === tabName);

      if (selectedTab !== newSelectedTab) {
        this.tabs[selectedTab].selected = false;
        this.tabs[newSelectedTab].selected = true;
      }

      this.pending = false;
    },
    filterChannelsByType(type) {
      return this.channels.filter(channel => channel.channelType.key === type);
    },
    changeChannelTab(tab) {
      this.isNewChannel = false;
      this.editedTab = null;
      this.selectedChannelTab = tab;
      this.changeMode('detail');
    },
    changeChannel(channel) {
      this.selectedChannel = channel;
    },
    toggleCreateChannel(tab) {
      this.isNewChannel = true;
      this.selectedChannelTab = tab;
      this.changeMode('empty');
    },
    createNewChannel(channel) {
      this.selectedChannel = channel;
      this.selectedChannel.campaign = this.currentCampaign.id;
      this.changeMode('create');
    },
    refreshChannel(updatedChannel, actualChannel, tabName) {
      this.isNewChannel = false;
      this.unsavedChanges = false;

      const channel = actualChannel || this.selectedChannel;

      this.setUpdatedChannels(updatedChannel, channel, tabName);
    },
    toggleChanges(value) {
      this.unsavedChanges = value;
    },
    cleanData() {
      this.tabs[0].selected = true;
      for (let i = 1; i < this.tabs.length; i += 1) {
        this.tabs[i].selected = false;
      }
      this.selectedChannel = null;
      this.selectedChannelTab = null;
      this.editedTab = null;
      this.unsavedChanges = false;
      this.isNewChannel = false;
    },
  },
  created() {
    this.pending = true;
    this.setChannels();
  },

};
</script>

<style lang="scss" scoped>
@import "~styles/components/_campaign-configuration.scss";
@import "~styles/views/channelSettings/_campaign-channels-settings.scss";
</style>
