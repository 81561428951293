import { render, staticRenderFns } from "./ChannelMessages.vue?vue&type=template&id=8f5e2442&scoped=true&"
import script from "./ChannelMessages.vue?vue&type=script&lang=js&"
export * from "./ChannelMessages.vue?vue&type=script&lang=js&"
import style0 from "./ChannelMessages.vue?vue&type=style&index=0&id=8f5e2442&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f5e2442",
  null
  
)

export default component.exports