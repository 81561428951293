<template>
  <div class="channels__info">
    <div class="channels__group">
      <div>
        <hcc-input
          :label="fanPageID"
          v-model="fanPage"
          customClass="channels__input"
          :disabled="pending"
          @input="changeData($event, 'fanPageId')"
        />
        <div class="channels__text">
          {{ findID }}
          <a
            href="https://findmyfbid.in/"
            target="_blank"
            class="channels__text-link"
            >findmyfbid</a
          >
        </div>
      </div>
    </div>
    <div class="channels__group">
      <hcc-button
        variant="outline"
        color="primary"
        v-if="!token"
        @click="logInWithFacebook"
        >{{ loginWFacebook }}
      </hcc-button>
    </div>
    <div class="channels__group">
      <hcc-input
        :label="accessToken"
        v-model="token"
        customClass="channels__input"
        :disabled="pending"
        @input="changeData($event, 'accessToken')"
      />
    </div>
  </div>
</template>

<script>
import HccInput from '@/components/shared/HccInput/index.vue';
import HccButton from '@/components/shared/HccButton/index.vue';

export default {
  components: {
    HccInput,
    HccButton,
  },
  props: {
    channel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fanPage: '',
      token: '',
      extras: {},
    };
  },
  inject: ['isPending'],
  computed: {
    fanPageID() {
      return this.$t('channels-settings.messenger.fan-page-id');
    },
    accessToken() {
      return this.$t('channels-settings.messenger.access-token');
    },
    findID() {
      return this.$t('channels-settings.messenger.find-id');
    },
    loginWFacebook() {
      return this.$t('channels-settings.messenger.loginFb');
    },
    pending() {
      return this.isPending();
    },
  },
  methods: {
    changeData(value, key) {
      this.extras[key] = value;
      this.messengerExtras();
    },
    messengerExtras() {
      this.$emit('update-extras', this.extras);
    },
    logInWithFacebook() {
      const vm = this;
      window.FB.login(({ authResponse }) => {
        if (authResponse) {
          vm.changeData(authResponse.accessToken, 'accessToken');
          vm.token = authResponse.accessToken;
        }
      }, { scope: 'public_profile,pages_messaging' });
    },
    loadFacebookSDK(doc, tag, id) {
      const fjs = doc.getElementsByTagName(tag)[0];
      if (doc.getElementById(id)) {
        return;
      }

      const js = doc.createElement(tag);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    },
    eventsFb() {
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: process.env.VUE_APP_FB_APP_ID,
          cookie: true,
          xfbml: true,
          version: process.env.VUE_APP_FB_APP_VERSION,
        });

        window.FB.AppEvents.logPageView();
      };
    },
  },
  created() {
    this.fanPage = this.channel.extras ? this.channel.extras.fanPageId : '';
    this.token = this.channel.extras ? this.channel.extras.accessToken : '';
    this.extras = { fanPageId: this.fanPage };
    this.eventsFb();
    this.loadFacebookSDK(document, 'script', 'facebook-jssdk');
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/views/channelSettings/_campaign-channels-settings.scss";
</style>
