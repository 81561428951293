<template>
  <div>
    <enable-settings
      :title="enableSettingsTitle"
      titleKey="schedules"
      :toggleValue="enable"
      @toggle-enable="toggleEnable"
    />
    <div class="cell" :class="!enable && 'schedules__container-disabled'">
      <hcc-table
        :title="schedulesSettings['table-title']"
        :initialPage="1"
        :columns="columns"
        :rows="rows"
        :pagination="false"
        :isSchedule="true"
        :unclickable="!enable"
        @edit="editDay"
        @delete="deleteDay"
      >
        <template slot="table-no-actions">
          <hcc-select
            :label="$t('configuration.schedules.time-zone')"
            custom-class="select"
            v-model="scheduleTimezone"
            :options="timezoneList"
            optionLabel=""
            :disabled="!enable"
          />
        </template>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'is24'">
            <hcc-toggle-switch
              size="sm"
              :value="props.row.is24"
              :name="'toggle' + props.row.name"
              :disabled="!enable"
              @change="toggle24($event, props.row.weekday)"
            />
          </span>
          <span v-else-if="props.column.field === 'range1'">
            <span v-if="props.row.schedules.length > 0">
              {{
                getRange(
                  props.row.schedules[0].start,
                  props.row.schedules[0].end
                )
              }}
            </span>
            <span v-else>- - - - - - - - - -</span>
          </span>
          <span v-else-if="props.column.field === 'range2'">
            <span v-if="props.row.schedules.length > 1">
              {{
                getRange(
                  props.row.schedules[1].start,
                  props.row.schedules[1].end
                )
              }}
            </span>
            <span v-else>- - - - - - - - - -</span>
          </span>
          <span v-else-if="props.column.field === 'range3'">
            <span v-if="props.row.schedules.length > 2">
              {{
                getRange(
                  props.row.schedules[2].start,
                  props.row.schedules[2].end
                )
              }}
            </span>
            <span v-else>- - - - - - - - - -</span>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </hcc-table>
      <div class="channels__edit">
        <hcc-button :disabled="!enable" @click="handleConfirm">{{
          save
        }}</hcc-button>
      </div>
      <transition name="fade" v-if="enable">
        <edit-channel-schedules
          :day="day"
          @update-schedules="updateSchedules"
        />
      </transition>
      <transition name="fade">
        <hcc-confirmation />
      </transition>
      <!--<transition name="fade">
      <error-modal :message="error" />
    </transition>-->
    </div>
  </div>
</template>

<script>
import HccTable from '@/components/shared/HccTable/index.vue';
import HccConfirmation from '@/components/shared/HccConfirmation/index.vue';
import HccButton from '@/components/shared/HccButton/index.vue';
import HccToggleSwitch from '@/components/shared/HccToggleSwitch/index.vue';
import HccSelect from '@/components/shared/HccSelect/index.vue';
import EnableSettings from './EnableSettings.vue';
import EditChannelSchedules from './EditSchedulesModal.vue';

export default {
  components: {
    HccTable,
    HccConfirmation,
    HccButton,
    HccToggleSwitch,
    EnableSettings,
    EditChannelSchedules,
    HccSelect,
  },
  props: {
    channel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      enable: false,
      unsavedChanges: false,
      day: {},
      columns: [],
      defaultTimeZone: 'America/Mexico_City',
      scheduleTimezone: this.channel.timezone,
      timezoneList: this.$moment.tz.names(),
      defaultColumns: [
        {
          label: this.$t('channels-settings.schedules.day'),
          field: 'name',
        },
        {
          label: '24H',
          field: 'is24',
          sortable: false,
        },
      ],
      rows: [
        {
          name: this.$t('channels-settings.schedules.mon'),
          weekday: 1,
          schedules: [],
        },
        {
          name: this.$t('channels-settings.schedules.tue'),
          weekday: 2,
          schedules: [],
        },
        {
          name: this.$t('channels-settings.schedules.wed'),
          weekday: 3,
          schedules: [],
        },
        {
          name: this.$t('channels-settings.schedules.thu'),
          weekday: 4,
          schedules: [],
        },
        {
          name: this.$t('channels-settings.schedules.fri'),
          weekday: 5,
          schedules: [],
        },
        {
          name: this.$t('channels-settings.schedules.sat'),
          weekday: 6,
          schedules: [],
        },
        {
          name: this.$t('channels-settings.schedules.sun'),
          weekday: 7,
          schedules: [],
        },
      ],
    };
  },
  computed: {
    schedulesSettings() {
      return this.$t('channels-settings.schedules');
    },
    enableSettingsTitle() {
      return this.$t('channels-settings.enable-settings.title', { type: this.schedulesSettings.title });
    },
    save() {
      return this.$t('channels-settings.save');
    },
  },
  inject: ['updateChannel'],
  methods: {
    delete(day) {
      return this.$t('channels-settings.schedules.delete', { day });
    },
    confirmDelete(day) {
      return this.$t('channels-settings.schedules.confirm-delete', { day });
    },
    deleteDay(params) {
      if (params.schedules.length === 0) {
        this.$toasted.global.error(this.schedulesSettings['empty-day']);
      } else {
        this.$modal.show('confirmation', {
          title: this.delete(params.name),
          description: this.confirmDelete(params.name),
          variant: 'error',
          confirm: () => {
            this.unsavedChanges = true;
            this.$emit('toggle-change', this.unsavedChanges);
            this.deleteDaySchedules(params.weekday);
          },
        });
      }
    },
    deleteDaySchedules(weekday) {
      const day = this.rows.find(row => row.weekday === weekday);
      day.schedules = [];
      day.is24 = false;
      this.fillColumns();
    },
    toggle24(event, weekday) {
      const day = this.rows.find(row => row.weekday === weekday);

      if (event) {
        day.schedules = [{
          start: new Date('2021-01-01 00:00'),
          end: new Date('2021-01-01 23:59'),
          weekday,
        }];
        day.is24 = true;
        this.fillColumns();
      } else {
        this.deleteDaySchedules(weekday);
      }
    },
    editDay(row) {
      this.unsavedChanges = true;
      this.$emit('toggle-change', this.unsavedChanges);
      this.day = row;
      this.$modal.show('edit-channel-schedules-modal');
    },
    cancel(props) {
      if (props) {
        const sch = props;
        sch.is24 = true;
      }
      this.schedule = {};
    },
    toggleEnable() {
      this.enable = !this.enable;

      const channel = {
        id: this.channel.id,
        overrideSchedules: this.enable,
      };

      this.updateChannel(channel, this.channel, this.schedulesSettings.title);
    },
    handleConfirm() {
      this.unsavedChanges = false;
      this.$emit('toggle-change', this.unsavedChanges);

      let schedules = [];

      this.rows.forEach((row) => {
        schedules = [...schedules, ...row.schedules];
      });

      const channel = {
        id: this.channel.id,
        timezone: this.scheduleTimezone,
        schedules: schedules.map(schedule => ({
          start: schedule.start,
          end: schedule.end,
          weekday: schedule.weekday,
        })),
      };

      this.updateChannel(channel, this.channel, this.schedulesSettings.title);
    },
    getRange(start, end) {
      const timezone = this.scheduleTimezone ?? this.defaultTimeZone;
      const startDate = this.$moment(start).tz(timezone).format('HH:mm');
      const endDate = this.$moment(end).tz(timezone).format('HH:mm');
      return `${startDate} - ${endDate}`;
    },
    getIs24(start, end) {
      if (!start || !end) {
        return false;
      }
      const startDate = this.$moment(start);
      const endDate = this.$moment(end);

      // this checks that the difference is 1439min
      // because the backend is storing startDate and endDate with 1 day of difference
      const difference = Math.ceil(this.$moment.duration(endDate.diff(startDate)).asMinutes());

      return difference === 1439 || difference === 1440 || Math.abs(difference) < 1;
    },
    fillDays(defaults = []) {
      const schedules = defaults;
      this.rows = this.rows.map((day) => {
        const schedule = schedules.filter(sch => sch.weekday === day.weekday);
        if (schedule.length > 0) {
          const [sch] = schedule;
          return {
            ...day,
            schedules: schedule,
            is24: this.getIs24(sch.start, sch.end),
          };
        }
        return day;
      });

      this.fillColumns();
    },
    fillColumns() {
      let max = 1;

      this.rows.forEach((row) => {
        max = Math.max(row.schedules.length, max);
      });

      this.columns = [this.defaultColumns[0]];
      for (let i = 0; i < max; i += 1) {
        this.columns.push({
          label: `${this.$t('channels-settings.schedules.range')}`,
          field: `range${i + 1}`,
        });
      }
      this.columns.push(this.defaultColumns[1]);
    },
    updateSchedules(schedules) {
      this.fillDays(schedules);
    },
  },
  created() {
    this.enable = this.channel ? this.channel.overrideSchedules : false;
    this.fillDays(this.channel.schedules || []);
  },
};
</script>

<style scoped lang="scss">
@import "~styles/settings/shared/_modal.scss";
@import "~styles/components/settings/_schedule-tab.scss";
@import "~styles/views/channelSettings/_campaign-channels-settings.scss";
</style>
