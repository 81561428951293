<template>
  <div class="channels__info">
    <div class="channels__group">
      <span>{{ channelIDLabel }}</span>
      <span class="channels__description">{{ channelID }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    channel: {
      type: Object,
      required: true,
    },
  },
  computed: {
    channelIDLabel() {
      return this.$t('channels-settings.webchat.channel-id');
    },
    channelID() {
      return this.channel.extras ? this.channel.extras.id : '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/views/channelSettings/_campaign-channels-settings.scss";
</style>
