<template>
  <hcc-modal
    name="edit-channel-schedules-modal"
    :clickToClose="false"
    @opened="setSchedules"
    @closed="clearData"
    @confirm="handleConfirm"
    @cancel="cancelEdit"
  >
    <template #header>
      <div class="schedules__modal-header">
        <span class="schedules__modal-title">{{ edit }}</span>
        <hcc-button-icon
          color="gray-light"
          square
          :disabled="!canAddSchedule()"
          @click="addNew"
        >
          <plus-icon />
        </hcc-button-icon>
      </div>
    </template>
    <transition-group name="slide-up">
      <div
        v-for="(schedule, index) in daySchedules"
        :key="index + schedule.weekday"
      >
        <div class="schedules__edit">
          <div>{{ getScheduleNumber(index + 1) }}</div>
          <div class="schedules__item">
            <div>
              <div
                class="schedules__title"
                :class="{ 'schedules__title-error': error === index }"
              >
                {{ scheduleSettings.start }}
              </div>
              <time-picker
                :class="{ error: error === index }"
                :input-width="55"
                :show-meridian="false"
                :controls="false"
                v-model="schedule.start"
                @input="validateDay"
              />
            </div>
            <div class="schedules__item-margin">
              <div
                class="schedules__title"
                :class="{ 'schedules__title-error': error === index }"
              >
                {{ scheduleSettings.end }}
              </div>
              <time-picker
                :class="{ error: error === index }"
                :input-width="55"
                :show-meridian="false"
                :controls="false"
                v-model="schedule.end"
                @input="validateDay"
              />
            </div>

            <hcc-button-icon
              v-if="index !== 0"
              @click="deleteSchedule(index)"
              class="schedules__icon"
            >
              <delete-icon />
            </hcc-button-icon>
          </div>
        </div>
        <div class="schedules__item-error" v-if="error === index">
          {{ scheduleSettings["schedule-error"] }}
        </div>
      </div>
    </transition-group>
    <template #footer>
      <div class="modal__buttons">
        <hcc-button
          size="sm"
          variant="outline"
          color="muted"
          @click="handleCancel"
          >{{ modal.cancel }}</hcc-button
        >
        <hcc-button
          size="sm"
          class="modal__buttons-button"
          @click="handleConfirm"
          >{{ modal.confirm }}</hcc-button
        >
      </div>
    </template>
  </hcc-modal>
</template>

<script>
import { TimePicker } from 'uiv';
import HccModal from '@/components/shared/HccModal/index.vue';
import HccButton from '@/components/shared/HccButton/index.vue';
import HccButtonIcon from '@/components/shared/HccButtonIcon/index.vue';

export default {
  components: {
    TimePicker,
    HccModal,
    HccButton,
    HccButtonIcon,
  },
  props: {
    day: {
      type: Object,
      required: true,
    },
  },
  computed: {
    scheduleSettings() {
      return this.$t('channels-settings.schedules');
    },
    modal() {
      return this.$t('shared-components.confirmation');
    },
    edit() {
      return this.$t('channels-settings.schedules.edit-day', { day: this.day ? this.day.name : '' });
    },
  },
  data() {
    return {
      daySchedules: [],
      dayTimes: [],
      error: -1,
      defaultSchedule: {
        start: new Date('January 01, 2021 09:00:00'),
        end: new Date('January 01, 2021 13:00:00'),
      },
    };
  },
  methods: {
    setSchedules() {
      this.daySchedules = this.day.schedules.map(schedule => ({
        ...schedule,
        start: new Date(schedule.start),
        end: new Date(schedule.end),
      }));

      if (this.daySchedules.length === 0) {
        this.daySchedules.push({
          ...this.defaultSchedule,
          weekday: this.day.weekday,
        });
      }
    },
    getScheduleNumber(num) {
      return this.$t('channels-settings.schedules.schedule', { num });
    },
    canAddSchedule() {
      return this.daySchedules.length < 3;
    },
    addNew() {
      this.daySchedules.push(this.create());
    },
    create() {
      return {
        ...this.defaultSchedule,
        weekday: this.day.weekday,
      };
    },
    deleteSchedule(id) {
      this.daySchedules.splice(id, 1);
    },
    cancelEdit() {
      this.$emit('cancel', this.schedules);
    },
    handleConfirm() {
      const [index, isValid] = this.validateDay();
      if (isValid) {
        this.error = -1;
        this.$emit('update-schedules', this.daySchedules);
        this.$modal.hide('edit-channel-schedules-modal');
      } else {
        this.error = index;
      }
    },
    clearData() {
      this.daySchedules = [];
      this.error = -1;
    },
    validateDay() {
      let { start, end } = this.daySchedules[0];

      start = this.$moment(start).local().format('HHmm');
      end = this.$moment(start).local().format('HHmm');

      // eslint-disable-next-line no-restricted-syntax
      for (const [i, schedule] of this.daySchedules.entries()) {
        const scheduleStart = this.$moment(schedule.start).local().format('HHmm');
        const scheduleEnd = this.$moment(schedule.end).local().format('HHmm');

        if (!(scheduleStart >= end && scheduleEnd >= start)) {
          return [i, false];
        }

        start = scheduleStart;
        end = scheduleEnd;
      }

      return [-1, true];
    },
    hasError() {
      return this.error !== -1;
    },
    handleCancel() {
      this.$emit('cancel');
      this.$modal.hide('edit-channel-schedules-modal');
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/views/channelSettings/_campaign-channels-settings.scss";
</style>
