<template>
  <div class="channels__info">
    <div class="channels__group">
      <span>{{ telegramSettings["bot-username"] }}</span>
      <span class="channels__description">{{ botUsername }}</span>
    </div>
    <div class="channels__group">
      <span>{{ telegramSettings.token }}</span>
      <span class="channels__description">{{ token }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    channel: {
      type: Object,
      required: true,
    },
  },
  computed: {
    telegramSettings() {
      return this.$t('channels-settings.telegram');
    },
    botUsername() {
      return this.channel.extras ? this.channel.extras.name : '';
    },
    token() {
      return this.channel.extras && this.channel.extras.token ? this.truncateWithDots(this.channel.extras.token, 40) : '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/views/channelSettings/_campaign-channels-settings.scss";
</style>
