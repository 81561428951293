<template>
  <div>
    <channel-edit
      v-if="isEditable"
      :channel="channel"
      :role="role"
      @cancel="changeMode('detail')"
      @update="updateChannel"
    />
    <div v-else>
      <div class="channels__detail">
        <div class="channels__container">
          <div class="channels__group">
            <span>
              {{ channelSettings.name }}
            </span>
            <div class="channels__group">
              <div class="channels__description">{{ channel.name }}</div>
              <div
                class="channels__badge"
                :class="`channels__badge-${channel.channelType.key}`"
              >
                {{ channel.channelType.name }}
              </div>
            </div>
          </div>
          <div class="channels__group" v-if="isOwner">
            <span>
              {{ channelSettings.status }}
            </span>
            <div class="channels__status-disabled">
              <span
                :class="
                  channel.enabled
                    ? 'channels__status-active'
                    : 'channels__status-inactive'
                "
                >{{ status }}</span
              >
              <hcc-toggle-switch
                name="toggle-status"
                :value="channel.enabled"
                disabled
              />
            </div>
          </div>
          <div class="channels__group" v-if="isOwner">
            <span>
              {{ channelSettings.url }}
            </span>
            <div class="channels__description">{{ channel.baseUrl }}</div>
          </div>
          <whatsapp-detail
            v-if="channelType === 'whatsapp'"
            :channel="channel"
          />
          <telegram-detail
            v-if="channelType === 'telegram' && isOwner"
            :channel="channel"
          />
          <messenger-detail
            v-if="channelType === 'messenger' && isOwner"
            :channel="channel"
          />
          <webchat-detail
            v-if="channelType === 'webchat' && isOwner"
            :channel="channel"
          />
        </div>
      </div>
      <div class="channels__edit" v-if="isOwner || isSuper">
        <hcc-button @click="changeMode('edit')">
          <pencil-icon /> {{ channelSettings.edit }}</hcc-button
        >
        <hcc-button
          v-if="isOwner"
          @click="deleteChannelConfirmation"
          color="alert"
        >
          <delete-icon /> {{ channelSettings.delete }}</hcc-button
        >
      </div>
    </div>
    <hcc-confirmation />
    <hcc-confirmation name="new-channel-confirmation">
      <template #footer>
        <div class="confirmation__buttons">
          <hcc-button
            @click="handleCancel"
            size="sm"
            variant="outline"
            color="muted"
            >{{ $t("shared-components.confirmation.exit") }}</hcc-button
          >
        </div>
      </template>
    </hcc-confirmation>
  </div>
</template>
<script>
import HccButton from '@/components/shared/HccButton/index.vue';
import HccToggleSwitch from '@/components/shared/HccToggleSwitch/index.vue';
import HccConfirmation from '@/components/shared/HccConfirmation/index.vue';
import ChannelEdit from '../ChannelEdit/index.vue';
import WhatsappDetail from './WhatsappDetail.vue';
import TelegramDetail from './TelegramDetail.vue';
import MessengerDetail from './MessengerDetail.vue';
import WebchatDetail from './WebchatDetail.vue';

export default {
  components: {
    HccButton,
    HccToggleSwitch,
    HccConfirmation,
    ChannelEdit,
    WhatsappDetail,
    TelegramDetail,
    MessengerDetail,
    WebchatDetail,
  },
  props: {
    channel: {
      type: Object,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
  },
  computed: {
    isOwner() {
      return this.role === 'owner';
    },
    isSuper() {
      return this.role === 'super';
    },
    isEditable() {
      return this.getMode() === 'edit' || this.getMode() === 'create' || this.getMode() === 'empty';
    },
    channelType() {
      return !this.isEditable ? this.channel.channelType.key : this.channel.key;
    },
    channelSettings() {
      return this.$t('channels-settings');
    },
    status() {
      return this.channel.enabled ? this.$t('channels-settings.active') : this.$t('channels-settings.inactive');
    },
    mode() {
      return this.getMode();
    },

  },
  inject: ['getMode', 'changeMode', 'deleteChannel'],
  methods: {
    updateChannel(updatedChannel, actualChannel, tabName) {
      this.$emit('update', updatedChannel, actualChannel, tabName);
    },
    deleteChannelConfirmation() {
      this.$modal.show('confirmation', {
        title: this.channelSettings['delete-channel'],
        description: this.$t('channels-settings.confirm-delete', { channel: this.channel.name }),
        variant: 'error',
        confirm: () => this.deleteChannel(this.channel)
          .then(() => {
            this.$emit('update', null, this.channel, this.channelSettings.detail);
          }),
      });
    },
    handleCancel() {
      this.$modal.hide('new-channel-confirmation');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/views/channelSettings/_campaign-channels-settings.scss";
</style>
