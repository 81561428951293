<template>
  <content-loader
    :width="400"
    :height="200"
    :speed="2"
    primaryColor="#c3c3c3"
    secondaryColor="#ecebeb"
    :animate="true"
  >
    <rect x="100" y="5" rx="0" ry="0" width="200" height="15" />
    <circle cx="140" cy="110" r="70" />
    <rect x="230" y="50" rx="0" ry="0" width="7" height="7" />
    <rect x="250" y="50" rx="0" ry="0" width="30" height="7" />
    <rect x="230" y="64" rx="0" ry="0" width="7" height="7" />
    <rect x="250" y="64" rx="0" ry="0" width="30" height="7" />
    <rect x="230" y="78" rx="0" ry="0" width="7" height="7" />
    <rect x="250" y="78" rx="0" ry="0" width="30" height="7" />
    <rect x="230" y="92" rx="0" ry="0" width="7" height="7" />
    <rect x="250" y="92" rx="0" ry="0" width="30" height="7" />
  </content-loader>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';

export default {
  components: {
    ContentLoader,
  },
};
</script>
